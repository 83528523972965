import React from "react";
import DasboardPage from './Pages/Dashboard';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginPage from "./Pages/Login/Login";
import CountryPage from "./Pages/Master/Country";
import ContractPage from "./Pages/Projects/Contract";
import ContractDetailPage from "./Pages/Projects/Contract/Detail";
import ProjectPage from "./Pages/Projects/Project";
import ProjectDetailPage from "./Pages/Projects/Project/Detail";
import CompanyPage from "./Pages/Master/Company";
import StaffPositionPage from "./Pages/Master/StaffPosition";
import LanguagePage from "./Pages/Master/Language";
import StaffSpecializationPage from "./Pages/Master/staffSpecialization"
import VesselTypePage from "./Pages/Master/VesselType";
import StaffPage from "./Pages/Master/Staff";
import StaffDetailPage from "./Pages/Master/Staff/Detail";
import CompanyDetailPage from "./Pages/Master/Company/Detail";
import DetailCountryPage from "./Pages/Master/Country/Detail";
import VesselClassificationSocietyPage from "./Pages/Master/VesselClassification/Society";
import VesselClassificationSocietyDetailPage from "./Pages/Master/VesselClassification/Society/Detail";
import VesselClassificationApplicationPage from "./Pages/Master/VesselClassification/Application";
import VesselClassificationApplicationDetailPage from "./Pages/Master/VesselClassification/Application/Detail";
import VesselClassificationCharacterPage from "./Pages/Master/VesselClassification/Character";
import VesselClassificationCharacterDetailPage from "./Pages/Master/VesselClassification/Character/Detail";
import VesselClassificationNotationPage from "./Pages/Master/VesselClassification/Notation";
import VesselClassificationNotationDetailPage from "./Pages/Master/VesselClassification/Notation/Detail";
import VesselPage from "./Pages/Master/Vessel";
import VesselDetailPage from "./Pages/Master/Vessel/Detail";
import VesselPhotoPage from "./Pages/Master/Vessel/VesselPhoto";
import VesselDocumentPage from "./Pages/Master/Vessel/Document";
import LanguageDetailPage from "./Pages/Master/Language/Detail";
import StaffPositionDetailPage from "./Pages/Master/StaffPosition/Detail";
import StaffSpecializationDetailPage from "./Pages/Master/staffSpecialization/Detail";
import VesselTypeDetailPage from "./Pages/Master/VesselType/Detail";
import EquipmentCategoryPage from "./Pages/Master/EquipmentCategory";
import EquipmentCategoryDetailPage from "./Pages/Master/EquipmentCategory/Detail";
import UserPage from "./Pages/Master/User";
import UserDetailPage from "./Pages/Master/User/Detail";
import ChangePasswordPage from "./Pages/Master/User/changePassword";
import VesselEquipmentPage from "./Pages/Master/VesselEquipment";
import VesselEquipmentDetailPage from "./Pages/Master/VesselEquipment/Detail";
import VesselEquipmentPhotoPage from "./Pages/Master/VesselEquipment/Photo";
// import ContractTaskPage from "./Pages/Projects/ContractTask";
import ContractTaskDetailPage from "./Pages/Projects/Contract/ContractTask/Detail";
import CompanyCategoryPage from "./Pages/Master/CompanyCategory";
import CompanyCategoryDetailPage from "./Pages/Master/CompanyCategory/Detail";
import VesselDetailEquipmentPage from "./Pages/Master/Vessel/DetailEquipment";
import CopyVesselEquipmentPage from "./Pages/Master/VesselEquipment/Copy";
import InventoryMasterPage from "./Pages/Inventory/InventoryMaster";
import InventoryWorkshopPage from "./Pages/Inventory/InventoryWorkshop";
import InventorySpecialToolPage from "./Pages/Inventory/InventorySpecialTool";
import InventoryGroupPage from "./Pages/Inventory/InventoryGroup";
import InventoryMasterDetailPage from "./Pages/Inventory/InventoryMaster/Detail";
import InventoryStockPage from "./Pages/Inventory/InventoryStock";
import InventoryStockDetailPage from "./Pages/Inventory/InventoryStock/Detail";
import InventoryTypePage from "./Pages/Inventory/InventoryType";
import DivisionPage from "./Pages/Inventory/Division";
import FullContractPage from "./Pages/Projects/Contract/FullContract";
import ContractDetailContractTaskPage from "./Pages/Projects/Contract/ContractTask";
import HandoverPage from "./Pages/Inventory/Handover";
import HandoverDetailPage from "./Pages/Inventory/Handover/Detail";
import SparepartCatalogPage from "./Pages/Master/SparepartCatalog";
import SparepartCatalogDetailPage from "./Pages/Master/SparepartCatalog/Detail";
import SparepartRequisitionPage from "./Pages/Master/SparepartRequisition";
import SparepartRequisitionDetailPage from "./Pages/Master/SparepartRequisition/Detail";
import ReturnPage from "./Pages/Inventory/Return";
import ReturnDetailPage from "./Pages/Inventory/Return/Detail";
import ServiceJobPage from "./Pages/Projects/ServiceJob";
import ServiceJobDetailPage from "./Pages/Projects/ServiceJob/Detail";
import MenuPage from "./Pages/SystemMenu/Menu";
import UserRoleXMenuPage from "./Pages/SystemMenu/UserRoleXMenu";
import UserRoleXMenuDetailPage from "./Pages/SystemMenu/UserRoleXMenu/Detail";
import UserAccountXMenuPage from "./Pages/SystemMenu/UserAccountXMenu";
import UserAccountXMenuDetailPage from "./Pages/SystemMenu/UserAccountXMenu/Detail";
import SurveyTypePage from "./Pages/Master/SurveyType";
import SurveySchedulePage from "./Pages/Master/Vessel/SurveySchedule";
// import SurveyScheduleDetailPage from "./Pages/Master/Vessel/SurveySchedule/Detail";
import MechanicGroupPage from "./Pages/Master/MechanicGroup";
import DetailMechanicGroupPage from "./Pages/Master/MechanicGroup/Detail";
import DetailDivisionPage from "./Pages/Inventory/Division/Detail";
import MechanicPage from "./Pages/Master/Staff";
import MechanicDetailPage from "./Pages/Master/Mechanic/Detail";
import EquipmentStructViewPage from "./Pages/Master/Vessel/EquipmentStructView";
import SparepartDocumentPage from "./Pages/Master/SparepartCatalog/Dokumen";
import VesselEquipmentDocumentPage from "./Pages/Master/VesselEquipment/Document";
import ContractDocumentPage from "./Pages/Projects/Contract/Document";
import ListProjectContractPage from "./Pages/Projects/Contract/ListProject";
import SparepartCatalogStockPage from "./Pages/Master/SparepartCatalog/Stock/Detail";
import SparepartCatalogStockIndexPage from "./Pages/Master/SparepartCatalog/Stock";
import WarehousePage from "./Pages/Master/Warehouse";
import RackPage from "./Pages/Master/Rack";
import RackCoordinatePage from "./Pages/Master/RackCoordinate";
import ManufacturerPage from "./Pages/Master/Manufacturer";
import SupplierClassPage from "./Pages/Supplier/SupplierClass";
import SupplierTypePage from "./Pages/Supplier/SupplierType";
import SupplierPage from "./Pages/Supplier";
import SupplierDetailPage from "./Pages/Supplier/Detail";
import SparepartReturnRequisitionPage from "./Pages/Master/SparepartReturnRequisition";
import SparepartReturnRequisitionDetailPage from "./Pages/Master/SparepartReturnRequisition/Detail";
import SparepartHandoverPage from "./Pages/Master/SparepartHandover";
import SparepartHandoverDetailPage from "./Pages/Master/SparepartHandover/Detail";
import InventoryRequisitionPage from "./Pages/Inventory/InventoryRequisition";
import InventoryRequisitionDetailPage from "./Pages/Inventory/InventoryRequisition/Detail";
import InventoryTypeDetailPage from "./Pages/Inventory/InventoryType/Detail";
import CustomerPage from "./Pages/Master/Customer";
import CustomerDetailPage from "./Pages/Master/Customer/Detail";
import ServiceDetailJobPhoto from "./Pages/Projects/ServiceJob/Photo";
import TroubleshootingPage from "./Pages/Projects/Troubleshooting";
import TroubleshootingDetailPage from "./Pages/Projects/Troubleshooting/Detail";
import TroubleshootingDetailPhotoPage from "./Pages/Projects/Troubleshooting/Photo";
import TechnicianDocumentPage from "./Pages/Master/TechnicianDocument";
import InventoryUnitTypePage from "./Pages/Master/InventoryUnitType";
import InventoryUnitTypeDetailPage from "./Pages/Master/InventoryUnitType/Detail";
import DocumentCategoryTechnicianPage from "./Pages/Master/DocumentCategoryTechnician";
import DocumentCategoryTechnicianDetailPage from "./Pages/Master/DocumentCategoryTechnician/Detail";
import PositionsPage from "./Pages/Master/Positions";
import EmployeeStatusPage from "./Pages/Master/EmployeeStatus";
import BankPage from "./Pages/Master/Bank";
import TermOfPaymentPage from "./Pages/Master/TermOfPayment";
import ProjectTypePage from "./Pages/Master/ProjectType";
import DocumentProjectTypePage from "./Pages/Master/DocumentProjectType";
import ProjectStatusPage from "./Pages/Master/ProjectStatus";
import ContractStatusPage from "./Pages/Master/ContractStatus";
import ServiceJobStatusPage from "./Pages/Master/ServiceJobStatus";
import TroubleshootingStatusPage from "./Pages/Master/TroubleshootinStatus";
import CustomerTypePage from "./Pages/Master/CustomerType";
import DashboarAcessPage from "./Pages/Master/DashboardAccess";
import LoginCustomerPage from "./Pages/Login/Customer";
import CrewPositionsPage from "./Pages/Master/CrewPositions";
import CrewRankPage from "./Pages/Master/CrewRank";
import SplashScreenPage from "./Pages/SplashScreen";
import MainEnginePage from "./Pages/MainEngine/MainEngine";
import MainEnginePortCalibrationPage from "./Pages/MainEngine/PortCalibration";
import LOPressLastBearing from "./Pages/MainEngine/LOPressLastBearing";
import PortCyl1 from "./Pages/MainEngine/PortCyl1";
import SpeedTemp from "./Pages/MainEngine/SpeedTemp";
import AuxEngine from "./Pages/AuxEngine";
import GearBox from "./Pages/GearBox";
import BilgesTanks from "./Pages/BilgesNTanks";
import LevelSoundingTanks from "./Pages/LevelSoundingTanks";
import Miscellaneous from "./Pages/Miscellaneous";
import Alarm from "./Pages/Alarm";
import AlarmLogs from "./Pages/Alarm/AlarmLogs";
import WheelHouse from "./Pages/FireAlarm/WheelHouse";
import MainDeck from "./Pages/FireAlarm/MainDeck";
import BelowMainDeck from "./Pages/FireAlarm/BelowMainDeck";
import NavSignalLightPanel from "./Pages/NavSignal";
import OutsideLighting from "./Pages/OutsideLighting";
import IncomingInvoicePage from "./Pages/Master/IncomingInvoice";
import InvoiceOutPage from "./Pages/Master/InvoiceOut";
import InventoryCategoryPage from "./Pages/Master/Vessel/InventoryCategory";
import InventoryCategoryDetailPage from "./Pages/Master/Vessel/InventoryCategory/Detail";
import VesselInventoryPage from "./Pages/Master/Vessel/InventoryMaster";
import VesselInventoryDetailPage from "./Pages/Master/Vessel/InventoryMaster/Detail";
import VesselInventoryStockPage from "./Pages/Master/Vessel/InventoryStock";
import VesselInventoryRequisitionPage from "./Pages/Master/VesselInventoryRequisition";
import VesselInventoryRequisitionDetailPage from "./Pages/Master/VesselInventoryRequisition/Detail";
import VesselHandoverInventoryPage from "./Pages/Master/VesselInventoryHandover";
import VesselHandoverDetailPage from "./Pages/Master/VesselInventoryHandover/Detail";
import VesselInventoryReturnPage from "./Pages/Master/VesselInventoryReturn";
import VesselInventoryReturnDetail from "./Pages/Master/VesselInventoryReturn/Detail";
import CrewDepartmentPage from "./Pages/Master/CrewDepartment";
import CrewDocumentCategoryPage from "./Pages/Master/CrewDocumentCategory";
import VesselSparepartRequisitionPage from "./Pages/Master/VesselSparepartRequisition";
import VesselSparepartRequisitionDetailPage from "./Pages/Master/VesselSparepartRequisition/Detail";
import VesselSpareapartHandoverPage from "./Pages/Master/VesselSparepartHandover";
import VesselSparepartHandoverDetailPage from "./Pages/Master/VesselSparepartHandover/Detail";
import VesselSparepartReturnPage from "./Pages/Master/VesselSparepartReturn";
import VesselSparepartReturnDetailPage from "./Pages/Master/VesselSparepartReturn/Detail.js";
import VesselSchedulePage from "./Pages/Master/Vessel/VesselSchedule/index.js";
import VesselScheduleDetailPage from "./Pages/Master/Vessel/VesselSchedule/Detail/index.js";
import VesselInventoryMasterPage from "./Pages/Master/VesselInventoryMaster/index.js";
import VesselInventoryHandoverDocumentPage from "./Pages/Master/VesselInventoryHandover/Document/index.js";
import LoadCellPage from "./Pages/Loadcell/index.js";
import MapsPage from "./Pages/MapsPage/index.js";
import LoadCellChartPage from "./Pages/LoadCellChart/index.js";
import BlogPage from "./Pages/BlogPage/index.js";
import PortalContentPage from "./Pages/PortalContent/index.js";
import DetailPortalContentPage from "./Pages/PortalContent/Detail/index.js";
import BlogDetailPage from "./Pages/BlogPage/Detail/index.js";
import WisdomWordPage from "./Pages/WisdomWord/index.js";
import WisdomWordDetailPage from "./Pages/WisdomWord/Detail/index.js";
import LeaveAllowancePage from "./Pages/HumanResource/LeaveAllowance/index.js";
import VesselPositionPage from "./Pages/VesselPosition/index.js";
import LeaveAllowanceDetailPage from "./Pages/HumanResource/LeaveAllowance/Detail/index.js";
import LeaveTypePage from "./Pages/HumanResource/LeaveType/index.js";
import LeaveTypeDetailPage from "./Pages/HumanResource/LeaveType/Detail/index.js";
import LeaveRequestPage from "./Pages/HumanResource/LeaveRequest/index.js";
import DailyReportPage from "./Pages/Projects/DailyReport/index.js";
import DailyReportDetailPage from "./Pages/Projects/DailyReport/Detail/index.js";
import DailyReportPhotoPage from "./Pages/Projects/DailyReport/Photo/index.js";
import DailyReportCrew from "./Pages/Projects/DailyReport/Technician/index.js";
import NewTroubleshootingPage from "./Pages/Projects/NewTroubleshooting/index.js";
import NewTroubleshootingDetailPage from "./Pages/Projects/NewTroubleshooting/Detail/index.js";
import NewTroubleshootingPhoto from "./Pages/Projects/NewTroubleshooting/Photo/index.js";
import DailyReportV2Page from "./Pages/Projects/DailyReportV2/index.js";
import DailyReportV2DetailPage from "./Pages/Projects/DailyReportV2/Detail/index.js";
import DashboardCarouselImagePage from "./Pages/DashboardCarousel/index.js";
import AboutUsPage from "./Pages/AboutUs/index.js";
import WatertightDoor from "./Pages/WatertightDoor/index.js";
import MSBPage from "./Pages/MSB/index.js";
import PumpMotor from "./Pages/PumpMotor/index.js";
import TankLevelsPage2 from "./Pages/BilgesTankPage2/index.js";
import PumpMotor2 from "./Pages/PumpMotor2/index.js";
import EquipmentMaintenanceSchedule from "./Pages/Master/Vessel/EquipmentMaintenanceSchedule/index.js";
import LanguageSetting from "./Pages/LanguageSetting/index.js";
import ChangePassword from "./Pages/ChangePassword/index.js";
import GeneralOverhaulIndexPage from "./Pages/GeneralOverhaul/index.js";
import GeneralOverhaulDetailPage from "./Pages/GeneralOverhaul/Detail/index.js";
import DailyReportFindingPhoto from "./Pages/Projects/DailyReport/FindingPhoto/index.js";
import InventoryBrandPage from "./Pages/Master/InventoryBrand/index.js";
import BusinessUnitPage from "./Pages/Master/BusinessUnit/index.js";
import GeneralOverhaulTableDataPage from "./Pages/GeneralOverhaul/DataTable/index.js";
import GOHJobPhoto from "./Pages/GeneralOverhaul/Photo/index.js";
import InventoryStockPhoto from "./Pages/Inventory/InventoryStock/StockImage/index.js";
import MeggerResultPage from "./Pages/Master/Vessel/MeggerResult/index.js";
// import TankCalibration from "./Pages/LevelSoundingTanks/TankCalibration";
import FlexPage from "./Pages/Flex/index.js";
import EquipmentFindingPage from "./Pages/Master/Vessel/EquipmentFinding/index.js";
import DailyReportDataTable from "./Pages/Projects/DailyReport/DataTable/index.js";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route exact path="/Dashboard" element={<BlogPage />} />
        <Route exact path="/Country" element={<CountryPage />} />
        <Route exact path="/Country/Detail" element={<DetailCountryPage />} />
        <Route exact path="/Contract" element={<ContractPage />} />
        <Route exact path="/Contract/Detail" element={<ContractDetailPage />} />
        <Route exact path="/Company" element={<CompanyPage />} />
        <Route exact path="/Company/Detail" element={<CompanyDetailPage />} />
        <Route exact path="/StaffPosition" element={<StaffPositionPage />} />
        <Route exact path="/StaffPosition/Detail" element={<StaffPositionDetailPage />} />
        <Route exact path="/Language" element={<LanguagePage />} />
        <Route exact path="/Language/Detail" element={<LanguageDetailPage />} />
        <Route exact path="/StaffSpecialization" element={<StaffSpecializationPage />} />
        <Route exact path="/StaffSpecialization/Detail" element={<StaffSpecializationDetailPage />} />
        <Route exact path="/VesselType" element={<VesselTypePage />} />
        <Route exact path="/VesselType/Detail" element={<VesselTypeDetailPage />} />
        <Route exact path="/VesselClassification/Society" element={<VesselClassificationSocietyPage />} />
        <Route exact path="/VesselClassification/Society/Detail" element={<VesselClassificationSocietyDetailPage />} />
        <Route exact path="/VesselClassification/Application" element={<VesselClassificationApplicationPage />} />
        <Route exact path="/VesselClassification/Application/Detail" element={<VesselClassificationApplicationDetailPage />} />
        <Route exact path="/VesselClassification/Character" element={<VesselClassificationCharacterPage />} />
        <Route exact path="/VesselClassification/Character/Detail" element={<VesselClassificationCharacterDetailPage />} />
        <Route exact path="/VesselClassification/Notation" element={<VesselClassificationNotationPage />} />
        <Route exact path="/VesselClassification/Notation/Detail" element={<VesselClassificationNotationDetailPage />} />
        <Route exact path="/Staff" element={<StaffPage />} />
        <Route exact path="/Staff/Detail" element={<StaffDetailPage />} />
        <Route exact path="/Vessel" element={<VesselPage />} />
        <Route exact path="/Vessel/Detail" element={<VesselDetailPage />} />
        <Route exact path="/Vessel/DetailEquipment" element={<VesselDetailEquipmentPage />} />
        <Route exact path="/Vessel/VesselPhoto" element={<VesselPhotoPage />} />
        <Route exact path="/Vessel/Document" element={<VesselDocumentPage />} />
        <Route exact path="/Vessel/SurveySchedule" element={<SurveySchedulePage />} />
        {/* <Route exact path="/Vessel/SurveySchedule/Detail" element={<SurveyScheduleDetailPage />}/> */}
        <Route exact path="EquipmentCategory" element={<EquipmentCategoryPage />} />
        <Route exact path="EquipmentCategory/Detail" element={<EquipmentCategoryDetailPage />} />
        <Route exact path="/User" element={<UserPage />} />
        <Route exact path="/User/Detail" element={<UserDetailPage />} />
        <Route exact path="/User/changePassword" element={<ChangePasswordPage />} />
        <Route exact path="/VesselEquipment" element={<VesselEquipmentPage />} />
        <Route exact path="/VesselEquipment/Detail" element={<VesselEquipmentDetailPage />} />
        <Route exact path="/VesselEquipment/Photo" element={<VesselEquipmentPhotoPage />} />
        <Route exact path="/Project" element={<ProjectPage />} />
        <Route exact path="/Project/Detail" element={<ProjectDetailPage />} />
        {/* <Route exact path="/ContractTask" element={<ContractTaskPage />} /> */}
        <Route exact path="/Contract/ContractTask/Detail" element={<ContractTaskDetailPage />} />
        <Route exact path="/CompanyCategory" element={<CompanyCategoryPage />} />
        <Route exact path="/SurveyType" element={<SurveyTypePage />} />
        <Route exact path="/CompanyCategory/Detail" element={<CompanyCategoryDetailPage />} />
        <Route exact path="/VesselEquipment/Copy" element={<CopyVesselEquipmentPage />} />
        <Route exact path="/InventoryMaster" element={<InventoryMasterPage />} />
        <Route exact path="/InventoryWorkshop" element={<InventoryWorkshopPage />} />
        <Route exact path="/InventorySpecialTool" element={<InventorySpecialToolPage />} />
        <Route exact path="/InventoryGroup" element={<InventoryGroupPage />} />
        <Route exact path="/InventoryMaster/Detail" element={<InventoryMasterDetailPage />} />
        <Route exact path="/InventoryStock" element={<InventoryStockPage />} />
        <Route exact path="/InventoryStock/Detail" element={<InventoryStockDetailPage />} />
        <Route exact path="/InventoryType" element={<InventoryTypePage />} />
        <Route exact path="/Division" element={<DivisionPage />} />
        <Route exact path="/Contract/FullContract" element={<FullContractPage />} />
        <Route exact path="/Contract/ContractTask" element={<ContractDetailContractTaskPage />} />
        <Route exact path="/Handover" element={<HandoverPage />} />
        <Route exact path="/Handover/Detail" element={<HandoverDetailPage />} />
        <Route exact path="/SparepartCatalog" element={<SparepartCatalogPage />} />
        <Route exact path="/SparepartCatalog/Detail" element={<SparepartCatalogDetailPage />} />
        <Route exact path="/SparepartRequisition" element={<SparepartRequisitionPage />} />
        <Route exact path="/SparepartRequisition/Detail" element={<SparepartRequisitionDetailPage />} />
        <Route exact path="/Return" element={<ReturnPage />} />
        <Route exact path="/Return/Detail" element={<ReturnDetailPage />} />
        <Route exact path="/ServiceJob" element={<ServiceJobPage />} />
        <Route exact path="/ServiceJob/Detail" element={<ServiceJobDetailPage />} />
        <Route exact path="/ServiceJob/Photo" element={<ServiceDetailJobPhoto />} />
        <Route exact path="/Menu" element={<MenuPage/>}/>
        <Route exact path="/UserRoleXMenu" element={<UserRoleXMenuPage/>}/>
        <Route exact path="/UserRoleXMenu/Detail" element={<UserRoleXMenuDetailPage/>}/>
        <Route exact path="/UserAccountXMenu" element={<UserAccountXMenuPage/>}/>
        <Route exact path="/UserAccountXMenu/Detail" element={<UserAccountXMenuDetailPage/>}/>
        <Route exact path="/MechanicGroup" element={<MechanicGroupPage/>}/>
        <Route exact path="/MechanicGroup/Detail" element={<DetailMechanicGroupPage/>}/>
        <Route exact path="/Division/Detail" element={<DetailDivisionPage/>}/>
        <Route exact path="/Mechanic" element={<MechanicPage/>}/>
        <Route exact path="/Mechanic/Detail" element={<MechanicDetailPage />} />
        <Route exact path="/Vessel/EquipmentStructView" element={<EquipmentStructViewPage />}/>
        <Route exact path="/SparepartCatalog/Document" element={<SparepartDocumentPage/>}/>
        <Route exact path="/VesselEquipment/Document" element={<VesselEquipmentDocumentPage/>}/>
        <Route exact path="/Contract/Document" element={<ContractDocumentPage/>}/>
        <Route exact path="/Contract/ListProject" element={<ListProjectContractPage/>}/>
        <Route exact path="/SparepartCatalog/Stock" element={<SparepartCatalogStockPage/>}/>
        <Route exact path="/SparepartCatalog/StockIndex" element={<SparepartCatalogStockIndexPage/>}/>
        <Route exact path="/Warehouse" element={<WarehousePage/>}/>
        <Route exact path="/Rack" element={<RackPage/>}/>
        <Route exact path="/RackCoordinate" element={<RackCoordinatePage/>}/>
        <Route exact path="/Manufacturer" element={<ManufacturerPage/>}/>
        <Route exact path="/Supplier/SupplierClass" element={<SupplierClassPage/>}/>
        <Route exact path="/Supplier/SupplierType" element={<SupplierTypePage/>}/>
        <Route exact path="/Supplier" element={<SupplierPage/>}/>
        <Route exact path="/Supplier/Detail" element={<SupplierDetailPage/>}/>
        <Route exact path="/SparepartReturnRequisition" element={<SparepartReturnRequisitionPage/>}/>
        <Route exact path="/SparepartReturnRequisition/Detail" element={<SparepartReturnRequisitionDetailPage/>}/>
        <Route exact path="/SparepartHandover" element={<SparepartHandoverPage/>}/>
        <Route exact path="/SparepartHandover/Detail" element={<SparepartHandoverDetailPage/>}/>
        <Route exact path="/InventoryRequisition" element={<InventoryRequisitionPage/>}/>
        <Route exact path="/InventoryRequisition/Detail" element={<InventoryRequisitionDetailPage/>}/>
        <Route exact path="/InventoryType/Detail" element={<InventoryTypeDetailPage/>}/>
        <Route exact path="/Customer" element={<CustomerPage/>}/>
        <Route exact path="/Customer/Detail" element={<CustomerDetailPage/>}/>
        <Route exact path="/Troubleshooting" element={<TroubleshootingPage/>}/>
        <Route exact path="/Troubleshooting/Detail" element={<TroubleshootingDetailPage/>}/>
        <Route exact path="/Troubleshooting/Photo" element={<TroubleshootingDetailPhotoPage/>}/>
        <Route exact path="/TechnicianDocument" element={<TechnicianDocumentPage/>}/>
        <Route exact path="/InventoryUnitType" element={<InventoryUnitTypePage/>}/>
        <Route exact path="/InventoryUnitType/Detail" element={<InventoryUnitTypeDetailPage/>}/>
        <Route exact path="/DocumentCategoryTechnician" element={<DocumentCategoryTechnicianPage/>}/>
        <Route exact path="/DocumentCategoryTechnician/Detail" element={<DocumentCategoryTechnicianDetailPage/>}/>
        <Route exact path="/StructuralPosition" element={<PositionsPage/>}/>
        <Route exact path="/EmployeeStatus" element={<EmployeeStatusPage/>}/>
        <Route exact path="/Bank" element={<BankPage/>}/>
        <Route exact path="/TermOfPayment" element={<TermOfPaymentPage/>}/>
        <Route exact path="/ProjectType" element={<ProjectTypePage/>}/>
        <Route exact path="/DocumentProjectType" element={<DocumentProjectTypePage/>}/>
        <Route exact path="/ProjectStatus" element={<ProjectStatusPage/>}/>
        <Route exact path="/ContractStatus" element={<ContractStatusPage/>}/>
        <Route exact path="/ServiceJobStatus" element={<ServiceJobStatusPage/>}/>
        <Route exact path="/TroubleshootingStatus" element={<TroubleshootingStatusPage/>}/>
        <Route exact path="/CustomerType" element={<CustomerTypePage/>}/>
        <Route exact path="/DasboardAccess" element={<DashboarAcessPage/>}/>
        <Route exact path="/Login/Customer" element={<LoginCustomerPage/>}/>
        <Route exact path="/Crew" element={<StaffPage/>}/>
        <Route exact path="/Crew/Detail" element={<StaffDetailPage/>}/>
        <Route exact path="/CrewPosition" element={<CrewPositionsPage/>}/>
        <Route exact path="/CrewRank" element={<CrewRankPage/>}/>
        <Route exact path="/Splashscreen" element={<SplashScreenPage/>}/>
        <Route exact path="/MainEngine" element={<MainEnginePage/>}/>
        <Route exact path="/MainEngine/PortCalibration" element={<MainEnginePortCalibrationPage/>}/>
        <Route exact path="/MainEngine/LOPressLastBearing" element={<LOPressLastBearing/>}/>
        <Route exact path="/MainEngine/PortCyl1" element={<PortCyl1/>}/>
        <Route exact path="/MainEngine/SpeedTemp" element={<SpeedTemp/>}/>
        <Route exact path="/AuxEngine" element={<AuxEngine/>}/>
        <Route exact path="/GearBox" element={<GearBox/>}/>
        <Route exact path="/BilgesTanks" element={<BilgesTanks/>}/>
        <Route exact path="/LevelSoundingTanks" element={<LevelSoundingTanks/>}/>
        <Route exact path="/Miscellaneous" element={<Miscellaneous />} />
        <Route exact path="/Alarm" element={<Alarm />} />
        <Route exact path="/Alarm/Logs" element={<AlarmLogs />} />
        <Route exact path="/FireAlarm/WheelHouse" element={<WheelHouse />} />
        <Route exact path="/FireAlarm/MainDeck" element={<MainDeck />} />
        <Route exact path="/FireAlarm/BelowMainDeck" element={<BelowMainDeck />} />
        <Route exact path="/NavSignalLightPanel" element={<NavSignalLightPanel />} />
        <Route exact path="/OutsideLighting" element={<OutsideLighting />} />
        <Route exact path="/IncomingInvoice" element={<IncomingInvoicePage/>}/>
        <Route exact path="/InvoiceOut" element={<InvoiceOutPage/>}/>
        <Route exact path="/Vessel/InventoryCategory" element={<InventoryCategoryPage/>}/>
        <Route exact path="/Vessel/InventoryCategory/Detail" element={<InventoryCategoryDetailPage/>}/>
        <Route exact path="/Vessel/InventoryMaster" element={<VesselInventoryPage/>}/>
        <Route exact path="/Vessel/InventoryMaster/Detail" element={<VesselInventoryDetailPage/>}/>
        <Route exact path="/Vessel/InventoryStock" element={<VesselInventoryStockPage/>}/>
        <Route exact path="/VesselInventoryRequisition" element={<VesselInventoryRequisitionPage/>}/>
        <Route exact path="/VesselInventoryRequisition/Detail" element={<VesselInventoryRequisitionDetailPage/>}/>
        <Route exact path="/VesselInventoryHandover" element={<VesselHandoverInventoryPage/>}/>
        <Route exact path="/VesselInventoryHandover/Detail" element={<VesselHandoverDetailPage/>}/>
        <Route exact path="/VesselInventoryReturn" element={<VesselInventoryReturnPage/>}/>
        <Route exact path="/VesselInventoryReturn/Detail" element={<VesselInventoryReturnDetail/>}/>
        <Route exact path="/CrewDepartment" element={<CrewDepartmentPage/>}/>
        <Route exact path="/CrewDocumentCategory" element={<CrewDocumentCategoryPage/>}/>
        <Route exact path="/VesselSparepartRequisition" element={<VesselSparepartRequisitionPage/>}/>
        <Route exact path="/VesselSparepartRequisition/Detail" element={<VesselSparepartRequisitionDetailPage/>}/>
        <Route exact path="/VesselSparepartHandover" element={<VesselSpareapartHandoverPage/>}/>
        <Route exact path="/VesselSparepartHandover/Detail" element={<VesselSparepartHandoverDetailPage/>}/>
        <Route exact path="/VesselSparepartReturn" element={<VesselSparepartReturnPage/>}/>
        <Route exact path="/VesselSparepartReturn/Detail" element={<VesselSparepartReturnDetailPage/>}/>
        <Route exact path="/Vessel/VesselSchedule" element={<VesselSchedulePage/>}/>
        <Route exact path="/Vessel/VesselSchedule/Detail" element={<VesselScheduleDetailPage/>}/>
        <Route exact path="/VesselInventoryMaster" element={<VesselInventoryMasterPage/>}/>
        <Route exact path="/VesselInventoryHandoverDocument" element={<VesselInventoryHandoverDocumentPage/>}/>
        <Route exact path="/Loadcell" element={<LoadCellPage/>}/>
        <Route exact path="/Maps" element={<MapsPage/>}/>
        <Route exact path="/Loadcell/Chart" element={<LoadCellChartPage/>}/>
        <Route exact path="/Schedule" element={<DasboardPage/>}/>
        <Route exact path="/Content" element={<PortalContentPage/>}/>
        <Route exact path="/Content/Detail" element={<DetailPortalContentPage/>}/>
        <Route exact path="/Blog/Detail" element={<BlogDetailPage/>}/>
        <Route exact path="/WisdomWord" element={<WisdomWordPage/>}/>
        <Route exact path="/WisdomWord/Detail" element={<WisdomWordDetailPage/>}/>
        <Route exact path="/LeaveAllowance" element={<LeaveAllowancePage/>}/>
        <Route exact path="/VesselPosition" element={<VesselPositionPage/>}/>
        <Route exact path="/LeaveAllowance/Detail" element={<LeaveAllowanceDetailPage/>}/>
        <Route exact path="/LeaveType" element={<LeaveTypePage/>}/>
        <Route exact path="/LeaveType/Detail" element={<LeaveTypeDetailPage/>}/>
        <Route exact path="/LeaveRequest" element={<LeaveRequestPage/>}/>
        <Route exact path="/DailyReport" element={<DailyReportPage/>}/>
        <Route exact path="/DailyReport/Detail" element={<DailyReportDetailPage/>}/>
        <Route exact path="/DailyReport/Photo" element={<DailyReportPhotoPage/>}/>
        <Route exact path="/DailyReport/Technician" element={<DailyReportCrew/>}/>
        <Route exact path="/NewTroubleshooting" element={<NewTroubleshootingPage/>}/>
        <Route exact path="/NewTroubleshooting/Detail" element={<NewTroubleshootingDetailPage/>}/>
        <Route exact path="/NewTroubleshooting/Photo" element={<NewTroubleshootingPhoto/>}/>
        <Route exact path="/DailyReportV2" element={<DailyReportV2Page/>}/>
        <Route exact path="/DailyReportV2/Detail" element={<DailyReportV2DetailPage/>}/>
        <Route exact path="/DashboardCarousel" element={<DashboardCarouselImagePage/>}/>
        <Route exact path="/AboutUs" element={<AboutUsPage/>}/> 
        <Route exact path="/WatertightDoor" element={<WatertightDoor/>}/>
        <Route exact path="/MSB" element={<MSBPage/>}/>
        <Route exact path="/PumpMotor" element={<PumpMotor/>}/>
        <Route exact path="/BilgesTanks2" element={<TankLevelsPage2/>}/>
        <Route exact path="/pumpMotor2" element={<PumpMotor2/>}/>
        <Route exact path="/EquipmentMaintenanceSchedule" element={<EquipmentMaintenanceSchedule/>}/>
        <Route exact path="/LanguageSetting" element={<LanguageSetting/>}/>
        <Route exact path="/ChangePassword" element={<ChangePassword/>}/>
        <Route exact path="/GeneralOverhaul" element={<GeneralOverhaulIndexPage/>}/>
        <Route exaxt path="/GeneralOverhaul/Detail" element={<GeneralOverhaulDetailPage/>}/>
        <Route exact path="/DailyReport/FindingPhoto" element={<DailyReportFindingPhoto/>}/>
        <Route exact path="/InventoryBrand" element={<InventoryBrandPage/>}/>
        <Route exact path="/BusinessUnit" element={<BusinessUnitPage/>}/>
        <Route exact path="/GeneralOverhaul/TableData" element={<GeneralOverhaulTableDataPage/>}/>
        <Route exact path="/GeneralOverhaul/Photo" element={<GOHJobPhoto/>}/>
        <Route exact path="/InventoryStock/Photo" element={<InventoryStockPhoto/>}/>
        <Route exact path="/Flex" element={<FlexPage/>}/>
        <Route exact path="/VesselEquipment/MeggerResult" element={<MeggerResultPage/>}/>
        <Route exact path="/VesselEquipment/EquipmentFinding" element={<EquipmentFindingPage/>}/>
        <Route exact path="/DailyReport/TableData" element={<DailyReportDataTable/>}/>

        
      </Routes>
    </BrowserRouter>
  )
}

